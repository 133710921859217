<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbFilterBar></RbFilterBar>

	2019-06-08	init

-->

<template>
	<div class="RbFilterBar hSpace hSpace--app font font--sans font--sizeSmall" :class="elmClasses" v-if="doShow">
		<div class="RbFilterBar__inner rbBorder rbBorder--bottom">
			<h5 class="RbFilterBar__title">{{title}}:</h5>
			<ul class="RbFilterBar__list">
				<li class="RbFilterBar__listItem">
					<router-link
						class="RbFilterBar__listItemLink"
						:class="!$route.query.filterId ? 'RbFilterBar__listItemLink--isActive' : ''"
						:to="{ name : 'RbShopView' }"
					>Alle</router-link>
				</li>
				<li class="RbFilterBar__listItem" v-for="(item, index) in filters" :key="index" >
					<router-link
						class="RbFilterBar__listItemLink"
						:class="$route.query.filterId === item.id ? 'RbFilterBar__listItemLink--isActive' : ''"
						:to="{ name : 'RbShopView', query: { filterId: item.id }}"
					>{{item.label}}</router-link>
				</li>
			</ul>
		</div>
		<!--
		<pre name="filters">{{filters}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'RbFilterBar',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			title: {
				type     : [String],
				default  : '',
				required : false,
			},
			filters: {
				type     : [Array, Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			doShow(){
				return this._.isArray( this.filters ) && this.filters.length ? true : false
			},
		},
		methods: {
			handleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.RbFilterBar { // debug
		/*
		&__inner { background-color: fade( red, 20 ); }
		&__list { background-color: fade( red, 20 ); }
		*/
	}
	.RbFilterBar { // vars
		--item-vPadding : 0.65em;
		--item-hPadding : 0.5em;
	}
	.RbFilterBar { // layout
		&__inner {
			display: flex;
		}
		&__title {
			min-width: 6em;
			padding: var(--item-vPadding) var(--item-vPadding) var(--item-vPadding) 0em;
		}

		&__list {
			position: relative;
			display: flex;
			list-style: none;
			margin: 0;
			overflow: hidden;
			flex-grow: 1;
		}
		&__listItem {
			text-transform: uppercase;
		}
	}
	.RbFilterBar { // styling
		&__list::after { // fadeout element
			//border: 1px solid red;
			position: absolute;
			top: 0; bottom: 0; right: 0;
			width: 2em;
			content: "";
			background-color: red;
			background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%);
		}

		&__listItemLink {
			transition: all 0.1s ease;
			display: inline-block;
			padding: var(--item-vPadding) var(--item-hPadding);
			color: inherit;
		}
		&__listItemLink--isActive,
		&__listItemLink:hover {
			color: @swatches[textGold];
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
