<template>
	<MhRouterView class="RbEventsView view" :isContentReady="isContentReady">

		<RbAppHeader
			:textRight="_.get( app, 'acfOptions.headerTextRight' )"
		></RbAppHeader>

		<RbAppMenu></RbAppMenu>

		<article class="view__inner">

			<!--
			<RbFilterBar
				:title="'Rubriken'"
				:filters="trashOnlyForDevFilters"
			/>
			-->

			<!-- subnav for upcoming and past events -->
			<div class="RbEventsView__subNav hSpace hSpace--app font font--sans font--sizeSmall">
				<a class="RbEventsView__subNavLink"
				   :class="{ 'RbEventsView__subNavLink--isActive' : !$route.query.filter }"
				   @click="gotoSubNavLink('/events/')"
				>Aktuelle</a>
				<a class="RbEventsView__subNavLink"
				   :class="{ 'RbEventsView__subNavLink--isActive' : $route.query.filter === 'past' }"
				   @click="gotoSubNavLink('/events/?filter=past')"
				>Vergangene</a>
				<!--
				<router-link class="RbEventsView__subNavLink RbEventsView__subNavLink--isActive" :to="{ name: 'RbEventsView' }">Aktuelle</router-link>
				<router-link class="RbEventsView__subNavLink" :to="{ name: 'RbEventsView', query: { filter: 'past' } }">Vergangene</router-link>
				-->
			</div>

			<!-- no events -->
			<div class="RbCartTable__bodyColumn hSpace hSpace--app" v-if="!_.size(eventPosts)">
				<br/>
				Derzeit keine kommenden Events.<br/>
				<br/>
			</div>

			<!-- list events -->
			<RbEventHeader
				v-for="(post, index) in eventPosts" :key="'e'+index"
				:endDate="_.get( post, 'acf.endDate')"
				:endTime="_.get( post, 'acf.endTime')"
				:title="_.get(post, 'title.rendered')"
				:link="_.get(post, 'link')"
				:tag="'h2'"
			></RbEventHeader>

		</article>

	</MhRouterView>
</template>

<script>
	import { EventBus } from '@/event-bus.js'
	import RestHandler from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js"
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'

	import RbAppHeader from "@/components/RbAppHeader.vue";
	import RbAppMenu from "@/components/RbAppMenu.vue";
	import RbSectionTitleRow from '@/components/RbSectionTitleRow.vue'
	import RbEventHeader from '@/components/RbEventHeader.vue'
	import RbAppFooter from "@/components/RbAppFooter.vue"
	import RbFilterBar from "@/components/RbFilterBar.vue"

	export default {
		name: 'RbEventsView',
		components: {
			MhRouterView,
			RbAppHeader,
			RbAppMenu,
			RbSectionTitleRow,
			RbEventHeader,
			RbAppFooter,
			RbFilterBar,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {
		},
		data() {
			return {
				isContentReady: false,
				eventPosts: [],
				trashOnlyForDevFilters: [
					{
						"id": "30",
						"label": "Literatur"
					},
					{
						"id": "31",
						"label": "Theorie"
					},
					{
						"id": "32",
						"label": "Kunst"
					},
					{
						"id": "33",
						"label": "Neu"
					}
				]
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			fetchEvents( finalCallback ){
				let thingsToLoad = 1

				const runCallback = ()=>{
					--thingsToLoad

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/events',
					params : {
						range : this.$route.query.filter === 'past' ? 'past' : 'nowAndFuture',
						perPage : -1,
					},
					callback : (response) => {
						this.eventPosts = response.data.result

						runCallback()
					},
				})
			},
			gotoSubNavLink( path ){ // $router.push with erros caused by duplicate navigation disabled
				this.$router.push( path ).catch(()=>{});
			},
		},
		mounted(){
			this.fetchEvents(()=>{
				this.isContentReady = true
			})
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.RbEventsView {

		&__subNav {
			--item-vPadding : 0.65em;
			--item-hPadding : 0.5em;

			&Link {
				transition: all 0.1s ease;
				display: inline-block;
				padding: var(--item-vPadding) var(--item-hPadding);
				color: inherit;
				text-transform: uppercase;
			}
			&Link:first-child { padding-left: 0; }
			&Link--isActive, &Link:hover { color: @swatches[textGold]; }
		}

	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
